import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import logo from './logo.jpg';
import './App.css';
import HomepageGallery from './HomepageGallery';
import Gallery from './Gallery';
import ClientList from './ClientList';
import Philanthropy from "./Philanthropy";


const importAll = (r) => (r.keys().map(r));
const zaynImages = importAll(require.context('./work/zayn', false, /\.(png|jpe?g|svg)$/));
const pumaImages = importAll(require.context('./work/puma', false, /\.(png|jpe?g|svg)$/));
const postImages = importAll(require.context('./work/post', false, /\.(png|jpe?g|svg)$/));
const bapef1Images = importAll(require.context('./work/bapef1', false, /\.(png|jpe?g|svg)$/));
const guessImages = importAll(require.context('./work/guess', false, /\.(png|jpe?g|svg)$/));
const jBalvinImages = importAll(require.context('./work/jbalvin', false, /\.(png|jpe?g|svg)$/));
const gagaMilanoImages = importAll(require.context('./work/gagamilano', false, /\.(png|jpe?g|svg)$/));
const palmsImages = importAll(require.context('./work/palms', false, /\.(png|jpe?g|svg)$/));
const vetPawImages = importAll(require.context('./work/vetpaw', false, /\.(png|jpe?g|svg)$/));
const forSomeoneImages = importAll(require.context('./work/forsomeone', false, /\.(png|jpe?g|svg)$/));
const steveAokiImages = importAll(require.context('./work/steveaoki', false, /\.(png|jpe?g|svg)$/));
const leoMessiImages = importAll(require.context('./work/leomessi', false, /\.(png|jpe?g|svg)$/));


export default function App() {
  return (
    <Router>
      <div>
        <ul className="navigation">
          <li>
            <Link to="/">Work</Link>
          </li>
          <li>
            <Link to="/clients">Clients</Link>
          </li>
          <li>
            <Link to="/philanthropy">Philanthropy</Link>
          </li>
        </ul>
        <header>
          <img alt="" className="logo" src={logo} />
        </header>
        <Routes>
          <Route path="/" element={<HomepageGallery />} />
          <Route path="/work/bape" element={<Gallery images={bapef1Images} />} />
          <Route path="/work/guess" element={<Gallery images={guessImages} />} />
          <Route path="/work/zayn" element={<Gallery images={zaynImages} />} />
          <Route path="/work/puma" element={<Gallery images={pumaImages} />} />
          <Route path="/work/post" element={<Gallery images={postImages} />} />
          <Route path="/work/jbalvin" element={<Gallery images={jBalvinImages} />} />
          <Route path="/work/gagamilano" element={<Gallery images={gagaMilanoImages} />} />
          <Route path="/work/palms" element={<Gallery images={palmsImages} />} />
          <Route path="/work/vetpaw" element={<Gallery images={vetPawImages} />} />
          <Route path="/work/forsomeone" element={<Gallery images={forSomeoneImages} />} />
          <Route path="/work/steveaoki" element={<Gallery images={steveAokiImages} />} />
          <Route path="/work/leomessi" element={<Gallery images={leoMessiImages} />} />
          <Route path="/clients" element={<ClientList />}/>
          <Route path="/philanthropy" element={<Philanthropy />}/>
        </Routes>
      </div>
    </Router>
  );
}
const Philanthropy = () => (<div className="philantrophy">
    <p>
    MAT ABAD is a cofounder of Karmagawa & Save the Reef<br />
    <br />
    <img className="kg-logo" src="http://karmagawa.org/wp-content/uploads/2019/12/kg-logo-small.png" />

    <br />
    <strong>“Karma”</strong> is the result of a person's actions, or destiny, and good karma is achieved through positive thoughts/actions
    <br />
    <br />
    <strong>“Gawa”</strong> means “to do or make” in the Tagalog language of the Philippines
    <br />
    <br />
    So, KarmaGawa means to make your own destiny where the more you give to others the more you receive... everything is connected. Our charity community aims to inspire and encourage people to embrace education, push their limits/challenge themselves, live with purpose and give back to others for maximum positivity!
    <br />
    <br />
    <img src="https://savethereef.org/assets/images/icons/logo-01.png" className="str-logo" />
    SaveTheReef is a project of Karmagawa, dedicated to saving the world’s oceans and marine life through a number of initiatives, including the fast-growing @savethereef account on Instagram and the launch of their upcoming film documentary.

    </p>

    </div>);

export default Philanthropy;
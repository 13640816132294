function importAll(r) {
    return r.keys().map(r);
}
const imageFolder = require.context('./clients', false, /\.(png|jpe?g|svg)$/);
const images = importAll(imageFolder);
  
  const ClientList = () => {
    return(
      <div className="clients-list">
        <p>Mat Abad is an LA-based fashion and commercial photographer. Born and raised in the Philippines. In 2014, he started channeling his creativity into photography and rose quickly through the ranks due to his unique aesthetic and natural talent, thus beginning his life's work - an odyssey behind the lens.</p>
          {images.map((image => (
            <div className="client-logo"><img alt="" src={image} /></div>
          )))}
      </div>
      
    )
  };
  
  export default ClientList;
import zayn from '../src/work/zayn/zayn-5.jpeg';
import puma from '../src/work/puma/1.jpeg';
import post from '../src/work/post/3.jpeg';
import bape from '../src/work/bapef1/AO9I6789.jpg';
import guess from '../src/work/guess/3.jpg';
import jbalvin from '../src/work/jbalvin/8K4A0325-copy.jpg'
import gagamilano from '../src/work/gagamilano/GAGA-MILANO1757-copy.jpg'
import cardi from '../src/work/palms/1CARDIB4895-copy-(1).jpg'
import vetpaw from '../src/work/vetpaw/AO9I1122.jpg'
import forSomeone from '../src/work/forsomeone/FORSOMEONE1699-copy.jpg'
import steveAoki from '../src/work/steveaoki/1.jpeg'
import leoMessi from '../src/work/leomessi/4.jpeg'

const images = [
    {image: guess, url: "/work/guess", title: "Guess Generations"},
    {image: bape, url: "/work/bape", title: "Bape x Formula 1"},
    {image: forSomeone, url: "/work/forsomeone", title: "For Someone"},
    {image: jbalvin, url: "/work/jbalvin", title: "JBALVIN x HARPERS BAZAAR"},
    {image: cardi, url: "/work/palms", title: "Palms Casino"},
    {image: gagamilano, url: "/work/gagamilano", title: "Gaga Milano"},
    {image: vetpaw, url: "/work/vetpaw", title: "VetPaw"},
    {image: zayn, url: "/work/zayn", title: "Zayn Malik"},
    {image: puma, url: "/work/puma", title: "Lil Yatchy for Puma"},
    {image: leoMessi, url: "/work/leomessi", title: "Leo Messi x Hawkers"},
    {image: steveAoki, url: "/work/steveaoki", title: "Steve Aoki x Hawkers"},
    {image: post, url: "/work/post", title: "Post Malone"},
]

const HomepageGallery = () => {
    return(
      <div className="gallery-container">
        <ul className="image-gallery">
          {images.map((image => (
            <a href={image.url}><li><img alt={image.title} src={image.image} />{image.title}</li></a>
          )))}
        </ul>
      </div>
    )
  };
    
  export default HomepageGallery;
const Gallery = ({images}) => {
  return(
    <div className="single-gallery-container">
      <ul className="image-gallery">
        {images.map((image => (
          <li><img alt="" src={image} /></li>
        )))}
      </ul>
    </div>
  )
};
  
export default Gallery;